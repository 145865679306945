import React from 'react';
import './Banner.scss';

function Banner() {
    return (
        <section className="hero is-dark is-medium is-bold">
            <div className="hero-body">
                <div className="columns">
                    <div className="column textContainer">
                        <div className="container centeredText">
                            <h1 className="title has-text-centered-mobile">
                                Cody Eilar
                            </h1>
                            <h2 className="subtitle has-text-centered-mobile">
                                Engineer, Scientist, Entrepeneur
                            </h2>
                        </div>
                    </div>
                    <div className="column centeredImage">
                        <img src="https://images.unsplash.com/photo-1569396116180-210c182bedb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1649&q=80" width="300px" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;