import React from 'react';

import './Contact.scss'

function Contact() {
    return (
        <section className="section">
            <div className="container">
                <h1 className="title has-text-centered">Find Me</h1>

                <div className="columns">
                    <div className="column has-text-centered">
                        <a href="mailto:cody@codyeilar.com">Email</a>
                    </div>
                    <div className="column has-text-centered">
                        <a href="https://github.com/acidleroy">GitHub</a>
                    </div>
                    <div className="column has-text-centered">
                        <a href="https://linkedin.com/in/cody-eilar">LinkedIn</a>
                    </div>

                </div>

            </div>
     </section>
    )

}
export default Contact; 