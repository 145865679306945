import React, { useEffect, useState } from 'react';

import './App.scss';

import Banner from './Banner';
import Contact from './Contact';


function App() {
  return (
    <>
      <Banner />
      <Contact />
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            ©2020 codyeilar.com, All Rights Reserved 
          </p>
        </div>
      </footer>
    </>

  );
}

export default App;
